import React from "react"
import { graphql } from "gatsby"
import SEO from '../components/seo'
import Layout from '../components/layout'
import ContentBlock from '../components/ContentBlock/ContentBlock'
import { Row, Col } from 'react-bootstrap'
import PageTitle from '../components/PageTitle/PageTitle'
import { Container } from 'react-bootstrap'

const Newsletters = ({ data }) => {
  
  const newsletters = data.allContentfulNewsletter.edges

  return (

<Layout>
<SEO title="USS IDAHO Newsletters"/>
<Container fluid className="container-fluid-full">
  <PageTitle pageTitle="Newsletters" >
    {/* {page.pageTitle} */}
  </PageTitle>
  <ContentBlock>
    <Row>
      <Col md={8}>
      <h2 className="text-center">Committee Newsletters</h2>
        <Row>
          {newsletters.map(({ node }) => {
            return (
              <>
                {!node.coNewsletter && (
                  <Col lg={4} md={6} sm={6}>
                  <a href={node.linkToPdf?.file.url} target="_blank" rel="noopener noreferrer">
                    <div className="text-center">
                      <p className="mb-0">{node.title} - {node.publishedDate}</p>
                      <img
                        style={{
                          width: '100%'
                        }}
                        src={node.image.fluid.src}
                        alt={node.title}
                      />
                      <p className="text-center"><small>Click to download PDF</small></p>
                    </div>
                    </a>
                  </Col>
                )}
              </>
              )}
              )
            }
        </Row>
      </Col>
      <Col md={4}>
        <h2 className="text-center">Ship's Newsletters</h2>
        <Row>
          {newsletters.map(({ node }) => {
            return (
              <>
                {node.coNewsletter === true && (
                  <Col lg={6}>
                  <a href={node.linkToPdf?.file.url} target="_blank" rel="noopener noreferrer">
                    <div className="text-center">
                      <p className="mb-0">{node.title} - {node.publishedDate}</p>
                      <img
                        style={{
                          width: '100%'
                        }}
                        src={node.image.fluid.src}
                        alt={node.title}
                      />
                      <p className="text-center"><small>Click to download PDF</small></p>
                    </div>
                    </a>
                  </Col>
                )}
              </>
              )}
              )
            }
        </Row>
      </Col>
    </Row>
   
   </ContentBlock>
    </Container>
</Layout>
  )
}
export const query = graphql`
{
  allContentfulNewsletter(sort: {fields: publishedDate, order: DESC}) {
    edges {
      node {
        coNewsletter
        image: newsletterThumbnail {
          fluid {
            src
          }
        }
        linkToPdf {
          file {
            url
            fileName
          }
        }
        title
        publishedDate(formatString: "MMMM D, YYYY")
      }
    }
  }
}
`

export default Newsletters
